<template>
  <div class="pre_filling">
    <div class="top">
    </div>
    <div class="patient_info">
        <div class="title">患者基本信息</div>
        <van-field v-model="preInfo.patientName" placeholder="请输入患者姓名" rows="1" required autosize label="患者姓名"></van-field>
        <van-field v-model="preInfo.patientGender" readonly name="picker" placeholder="点击选择患者性别" @click="showPick()" label="患者性别" required right-icon="arrow-down"></van-field>
        <van-field v-model="preInfo.patientAge" placeholder="请输入患者年龄" rows="1" required autosize label="患者年龄"></van-field>
    </div>
    <div class="patient_info">
      <div class="title ">周期1</div>
        <van-field v-model="preInfo.drugName" readonly name="picker" placeholder="点击选择药品名称" @click="showDrug()" label="药品名称" required right-icon="arrow-down"></van-field>
        <van-field v-model="preInfo.recipelDate" readonly name="picker" placeholder="点击选择处方日期" @click="openTime()" label="处方日期" required right-icon="arrow-down"></van-field>
        <van-field v-model="preInfo.doseValue" readonly name="picker" placeholder="点击选择处方剂量（支）" @click="openDose()" label="处方剂量（支）" required right-icon="arrow-down"></van-field>
        <Upload v-if="ifShowUpload" :deleteImg="deleteImg" :item="item"></Upload>
    </div>
    <div class="btn">
      <van-button round type="primary" size="large" class="save" :disabled="disabled" native-type="submit" plain
        hairline @click="save()">保存
      </van-button>
      <van-button round type="info" size="large" class="up" :disabled="disabled" native-type="submit"
        @click="next()">下一步
      </van-button>
    </div>
    <div class="goBack" @click="onBack()">
      <img src="/static/goBack.png" />
    </div>

    <!-- 患者性别 -->
    <van-popup v-model="showPicker" position="bottom">
        <van-picker cancel-button-text="清除" show-toolbar :columns="columns" @confirm="pickConfirm" @cancel="pickCancel" />
    </van-popup>
    <!-- 药品名称 -->
    <van-popup v-model="drugPicker" position="bottom">
        <van-picker cancel-button-text="清除" show-toolbar :columns="drugColumns" @confirm="pickConfirmDrug" @cancel="pickCancelDrug" />
    </van-popup>
    <!-- 处方日期 -->
    <van-popup v-model="timePicker" position="bottom">
        <van-datetime-picker cancel-button-text="清除" type="date" @confirm="timeConfirm" @cancel="timeCancel" v-model="time"/>
    </van-popup>
    <!-- 处方剂量 -->
    <van-popup v-model="dosePicker" position="bottom">
        <van-picker cancel-button-text="清除" show-toolbar :columns="doseColumns" @confirm="pickConfirmDose" @cancel="pickCancelDose" />
    </van-popup>
  </div>
</template>

<script>
import {
  Dialog,
  Toast,
} from "vant";
import {
  mapState
} from 'vuex'
import {
  savePreRecord,
  getPreRecord,
} from '@/api/api'
import Upload from '@/components/upload.vue'
export default {
  components: {
    Upload,
  },
  data() {
    return {
      preInfo: {
        patientName: '',    //患者姓名
        patientAge: '',     //患者年龄
        patientGender: '',  //患者性别
        drugName: '贝伐珠单抗',       //药品名称
        recipelDate: '',    //处方日期
        doseValue: '',      //处方剂量
      },
      // patientName: '',        //患者姓名
      // patientAge: '',         //患者年龄
      // patientGender: '',      //患者性别
      columns: ['男', '女'],  
      drugColumns: ['贝伐珠单抗'],
      doseColumns: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
      showPicker: false,
      drugPicker: false,
      timePicker: false,
      dosePicker: false,
      // drugName: '',           //药品名称
      // recipelDate: '',        //处方日期
      // doseValue: '',          //处方剂量
      time: new Date(),
      item: {
        type: 'file',
        label: '用药证明',
        require: 1,
        readonly: 0,
        upload_filecount: 9,
        upload_filetype: 'image',
        upload_maxsize: 10,
        value: [],
      },
      disabled: false,
      ifShowUpload: false,
      deleteImg: true,
    }
  },
  computed:{
    ...mapState(['doctor_id'])
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      console.log('doctor_id:', this.doctor_id);
      await getPreRecord({
        project_doctor_id: this.doctor_id
      }).then(res => {
        console.log('回显预填信息：',res);
        this.preInfo.patientName = res['患者姓名'] ? res['患者姓名'] : ''
        this.preInfo.patientGender = res['患者性别'] ? res['患者性别'] : ''
        this.preInfo.patientAge = res['患者年龄'] ? res['患者年龄'] : ''
        this.preInfo.drugName = res['药品名称'] ? res['药品名称'] : '贝伐珠单抗'
        this.preInfo.recipelDate = res['处方日期'] ? res['处方日期'] : ''
        this.preInfo.doseValue = res['处方剂量'] ? res['处方剂量'] : ''
        this.item.value = res['用药证明'] ? res['用药证明'] : [] 
        this.ifShowUpload = true
      })
    },
    showPick() {
      this.showPicker = true
    },
    pickConfirm(value) {
      this.preInfo.patientGender = value
      this.showPicker = false
    },
    pickCancel() {
      this.preInfo.patientGender = ''
      this.showPicker = false
    },
    showDrug() {
      this.drugPicker = true
    },
    pickConfirmDrug(value) {
      this.preInfo.drugName = value
      this.drugPicker = false
    },
    pickCancelDrug() {
      this.preInfo.drugName = ''
      this.drugPicker = false
    },
    openTime() {
      this.timePicker = true
    },
    timeConfirm(value) {
      const d = new Date(value)
      let time = ''
      time = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
      this.preInfo.recipelDate = time
      this.timePicker = false
      return
    },
    timeCancel() {
      this.preInfo.recipelDate = ''        
      this.timePicker = false
    },
    openDose() {
      this.dosePicker = true
    },
    pickConfirmDose(value) {
      this.dosePicker = false
      this.preInfo.doseValue = value
    },
    pickCancelDose() {
      this.preInfo.doseValue = ''
      this.dosePicker = false
    },
    async save() {
      let params = {
        '患者姓名': this.preInfo.patientName,
        '患者性别': this.preInfo.patientGender,
        '患者年龄': this.preInfo.patientAge,
        '药品名称': this.preInfo.drugName,
        '处方日期': this.preInfo.recipelDate,
        '处方剂量': this.preInfo.doseValue,
        '用药证明': this.item.value,
        "project_doctor_id": this.doctor_id,
      }
      await savePreRecord(params).then(res => {
        Toast('保存成功')
        console.log('保存预填信息:', res);
      })
      console.log('保存');
    },
    async next() {
      for(let i in this.preInfo) {
        console.log(this.preInfo[i]);
        if(!this.preInfo[i]) {
          Toast('请完善必填项')
          return
        }
      }
      if(this.item.value.length == 0) {
        Toast('请完善必填项')
        return
      }
      let params = {
        '患者姓名': this.preInfo.patientName,
        '患者性别': this.preInfo.patientGender,
        '患者年龄': this.preInfo.patientAge,
        '药品名称': this.preInfo.drugName,
        '处方日期': this.preInfo.recipelDate,
        '处方剂量': this.preInfo.doseValue,
        '用药证明': this.item.value,
        "project_doctor_id": this.doctor_id,
      }
      await savePreRecord(params).then(res => {
        console.log('保存预填信息:', res);
        this.$router.push({
          path: '/list'
        })
      }).catch(err => {
        Dialog.alert({
          title: '温馨提示',
          message:err.response.data.message,
          confirmButtonColor: "#4B99FF",
        })
      })
    },
    onBack() {
      console.log('返回');
      this.$router.replace({
        path: '/progress'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pre_filling{
  padding-bottom: 168px;
  .goBack {
    position: fixed;
    right: 11px;
    bottom: 150px;
    height: 45px;
    width: 45px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .btn {
    .save{
      color: #292929;
    }
  }
  .title {
    padding-left: 11px;
    box-sizing: border-box;
    font-size: 15px;
    color: #232323;
    margin-bottom: 13px;
    text-align: left;
  }
  .top {
    width: 100%;
    height: 77px;
    background-image: linear-gradient(to right, #5B9BF0, #3D87EA);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 14px;
  }
  .patient_info{
    margin-bottom: 30px;
    padding: 0 11px;
  }
}
</style>