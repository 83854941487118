<template>
  <div class="uploadPic">
    <!-- {{picList}} -->
    <van-field v-if="item.type == 'file'" :required="item.require == 1" class="filed">
      <template #label>
        <div class="picBox">
          <span>{{ item.label }}</span>
        </div>
      </template>
      <template #input>
        <div>
          <div class="picBox">
            <div class="list" v-for="(itm, idx) in preImages" :key="idx">
              <img :src="itm" class="img" @click="show('preImages', idx)" />
              <img src="/static/clear.png" class="clear" @click="delImg(idx)" v-if="item.readonly != 1" />
            </div>
            <div class="list" v-for="(itm, idx) in images" :key="idx">
              <img :src="itm" class="img" @click="show('images', idx)" />
              <img src="/static/clear.png" class="clear" @click="clear(itm, idx)" />
            </div>
            <template v-if="filetype && (filetype != 'image' || !isWeixin)">
              <template v-if="utm_source == 'iga_baidu'">
                <van-uploader v-if="item.readonly != 1" v-model="files" :before-read="beforeRead" :max-count="item.upload_filecount" upload-icon="add" :accept="getFileType(filetype)" :mutiple="androidAttrs2 ? true : false" :capture="androidAttrs2 ? 'camera' : null" preview-size="52" />
              </template>
              <template v-else>
                <van-uploader v-if="item.readonly != 1" v-model="files" :before-read="beforeRead" :max-count="item.upload_filecount" upload-icon="add" :accept="getFileType(filetype)" :mutiple="androidAttrs ? true : false" :capture="androidAttrs ? 'camera' : null" preview-size="52" />
              </template>
            </template>
            <template v-else>
              <div class="add" @click="chooseImage()" v-if="showAdd && item.readonly != 1">
                <img src="/static/upload.png" />
                <span>添加</span>
              </div>
            </template>
          </div>
          <div class="remarks">
            <div>{{ item.placeholder }}</div>
            <div>请上传{{ filetype }}文件，单张文件大小须小于{{ item.upload_maxsize }}M</div>
          </div>
        </div>
      </template>
    </van-field>
    <van-field v-if="item.type == 'file' && item.sample_image" class="filed" label="示例图片">
      <template #input>
        <van-image width="80" height="80" :src="item.sample_image" fit="contain" @click="preview(item.sample_image)" />
      </template>
    </van-field>
  </div>
</template>

<script>
import { DownloadImage, UploadFile, deleteImg, getWordOcr } from '@/api/api'
import { Toast, Dialog, ImagePreview } from 'vant'
import { mapActions, mapState } from 'vuex'
import { appHost } from '@/utils/host'
import Compressor from 'compressorjs'

export default {
  name: '',
  components: {},
  data() {
    return {
      images: [],
      preImages: [],
      baseFileUrl: appHost,
      filetype: '',
      files: [],
      maxsize: 0,
      short_name: '' //@判断是否是天晴，若是天晴才ocr识别
    }
  },
  props: ['item', 'value', 'record_id', 'case_id', 'deleteImg'],
  computed: {
    showAdd() {
      return parseInt(this.item.upload_filecount) > this.images.length + this.preImages.length
    },
    ...mapState(['basicInformation', 'utm_source'])
  },
  watch: {
    // item: {
    //     handler(val) {
    //         if (val.upload_filetype && val.upload_filetype !== 'image') {
    //             this.files = val.value
    //         } else {
    //             this.preImages = val.value
    //         }
    //         this.filetype = val.upload_filetype
    //         this.maxsize = parseInt(val.upload_maxsize) * 1048576
    //         console.log('watch upload', this.preImages, this.images)
    //     },
    //     deep: true,
    //     immediate: true
    // },
  },
  mounted() {
    this.init()
    // console.log("mounted@",this.basicInformation)
    this.short_name = this.basicInformation.projects[0].short_name
    console.log('short_name@upload', this.short_name)
  },
  methods: {
    init() {
      this.images = []
      if (this.item.upload_filetype && (this.item.upload_filetype !== 'image' || !this.isWeixin)) {
        // this.files = this.item.value
        this.files = []
        for (let i in this.item.value) {
          let ele = this.item.value[i]
          this.files.push({ url: ele })
        }
      } else {
        this.preImages = this.item.value
      }
      this.filetype = this.item.upload_filetype
      this.maxsize = parseInt(this.item.upload_maxsize) * 1048576
    },
    getFileType(val) {
      if (val == 'word') {
        return '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      } else if (val == 'ppt') {
        return '.ppt,.pptx,application/vnd.ms-powerpoint'
      } else if (val == 'pdf') {
        return '.pdf,application/pdf'
      } else {
        return 'image/*'
      }
    },
    checkFileType(ext) {
      if (this.filetype == 'word') {
        return ['doc', 'docx'].indexOf(ext) != -1 ? true : false
      } else if (this.filetype == 'ppt') {
        return ['ppt', 'pptx'].indexOf(ext) != -1 ? true : false
      } else if (this.filetype == 'pdf') {
        return ['pdf'].indexOf(ext) != -1 ? true : false
      } else {
        return true
      }
    },
    preview(file) {
      let images = []
      images.push(file)
      this.$nextTick(() => {
        ImagePreview(images)
      })
    },
    show(status, index) {
      let idx
      if (status == 'images') {
        idx = this.preImages.length + index
      } else {
        idx = index
      }
      let images = this.preImages.concat(this.images)
      this.$nextTick(() => {
        ImagePreview({
          images,
          startPosition: idx
        })
      })
    },
    delImg(index) {
      Dialog.confirm({
        message: '是否确定删除',
        confirmButtonColor: '#4B99FF',
        title: '温馨提示'
      })
        .then(() => {
          console.log('deleteImg:', this.deleteImg)
          if (this.deleteImg) {
            //肺肠安好项目预填信息页面的图片删除不调用接口
            this.preImages.splice(index, 1)
            let list = this.preImages.concat(this.images)
            this.item.value = list
            Toast('删除成功')
          } else {
            deleteImg({
              record_id: this.record_id ? this.record_id : this.$route.query.record_id ? this.$route.query.record_id : this.case_id,
              attach_name: this.item.name,
              attach_index: index
            })
              .then(res => {
                Toast('删除成功')
                this.preImages.splice(index, 1)
                let list = this.preImages.concat(this.images)
                this.item.value = list
                this.deleteOcr()
              })
              .catch(err => {
                Toast(err.response.data.message)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getImgByteSize(data) {
      let size = 0
      if (data) {
        // 获取base64图片byte大小
        const equalIndex = data.indexOf('=') // 获取=号下标
        if (equalIndex > 0) {
          const str = data.substring(0, equalIndex) // 去除=号
          const strLength = str.length
          const fileLength = strLength - (strLength / 8) * 2 // 真实的图片byte大小
          size = Math.floor(fileLength) // 向下取整
        } else {
          const strLength = data.length
          const fileLength = strLength - (strLength / 8) * 2
          size = Math.floor(fileLength) // 向下取整
        }
      }
      return size * 1.657
    },
    async readImages(localIds) {
      // alert(localIds.length)
      for (let i = 0; i < localIds.length; i++) {
        // alert(localIds.length)
        await this.doReadImage(localIds[i])
      }
    },
    doReadImage(localId) {
      let _this = this
      // alert('localId')
      return new Promise(resolve => {
        // alert('getLocalImgData')
        _this.$wechat.getLocalImgData({
          localId: localId,
          success: async response => {
            let localData = response.localData
            _this.picList = response
            let itemSize = await _this.getImgByteSize(localData) //获取base64大小
            console.log(itemSize, 'itemSize')
            if (itemSize > 20480000) {
              Toast('图片过大，请重新选择图片')
            } else {
              // alert("this.uploadImage(serverId)")
              await _this.uploadImage(localId)
            }
            resolve('done!')
          },
          fail: function () {
            Toast('多张图片')
          }
        })
      })
    },
    chooseImage() {
      // this.images.push('https://ecasev2-files.oss-cn-hangzhou.aliyuncs.com/upload/image/202211/08/cbdf5dfc3101f5038055066ae746f83c.jpg?OSSAccessKeyId=LTAI4FdisbELrnUkhP2aFodh&Expires=1667914830&Signature=DkRMsfRJsVTjXymHnATuKfMRS%2F4%3D')
      // let list = this.preImages.concat(this.images)
      // this.item.value = list
      // this.isLoading = false
      // return
      let _this = this
      let num = parseInt(this.item.upload_filecount) - this.preImages.length
      this.$wechat.chooseImage({
        count: num > 9 ? 9 : num,
        // sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: res => {
          _this.readImages(res.localIds)
          // try {
          //     for(let i in res.localIds) {
          //         const serverId = res.localIds[i];
          //         this.getLocalImgData(serverId)
          //     }
          // } catch (err) {
          //     console.log(err, 'err')
          //     Toast(err.response.data.message);
          // }
        },
        fail: function () {
          Toast('选图失败')
        }
      })
    },
    uploadImage(serverId) {
      // alert('uploadImage')
      return new Promise((resolve, reject) => {
        this.$wechat.uploadImage({
          localId: serverId,
          isShowProgressTips: 1,
          success: res => {
            var serverId = res.serverId // 返回图片的服务器端ID
            this.isLoading = true
            DownloadImage({
              params: {
                serverId: serverId
              }
            })
              .then(res => {
                this.images.push(res)
                let list = this.preImages.concat(this.images)
                this.item.value = list
                this.isLoading = false
                this.$forceUpdate()
                this.ocr()
                resolve()
              })
              .catch(err => {
                //  reject(err.response.data.message)
                console.log(err)
                this.isLoading = false
                reject(err)
              })
          }
        })
      })
    },
    clear(item, index) {
      Dialog.confirm({
        message: '是否确定删除',
        confirmButtonColor: '#4B99FF',
        title: '温馨提示'
      })
        .then(() => {
          this.images.splice(index, 1)
          let list = this.preImages.concat(this.images)
          this.item.value = list
          this.deleteOcr()
          // deleteImg({
          //     record_id: this.record_id ? this.record_id : (this.$route.query.record_id?this.$route.query.record_id:this.case_id),
          //     attach_name: this.item.name,
          //     attach_index: index,
          // }).then(res => {
          //     Toast('删除成功')
          //     this.preImages.splice(index, 1)
          //     let list = this.preImages.concat(this.images)
          //     this.item.value = list
          // this.deleteOcr()
          // }).catch(err => {
          //     Toast(err.response.data.message)
          // })
          // this.images.splice(index, 1)
          // this.ocr();
        })
        .catch(err => {
          console.log(err)
        })
    },
    async compressorImage(file) {
      return new Promise((resolve, reject) => {
        if (file.size <= 1024 * 1024) {
          resolve(file)
        } else {
          new Compressor(file, {
            quality: 0.6,
            converTypes: ['image/png', 'image/jpg'],
            convertSize: 1000000,
            success: result => {
              resolve(result)
            },
            error: err => {
              reject(err)
            }
          })
        }
      })
    },
    beforeRead(file) {
      if (file.size > this.maxsize) {
        Toast({
          duration: 1500,
          forbidClick: true,
          message: '上传失败，文件大小超过上传限制！'
        })
        return
      }
      const formData = new window.FormData()
      // const res = await this.compressorImage(file)
      // await formData.append('file', res)
      formData.append('file', file)
      this.$nextTick(async () => {
        UploadFile(formData)
          .then(res => {
            if (this.checkFileType(res.extension)) {
              this.files.push({ url: res.file_path })
              this.item.value = this.files.map(item => item.url)
            } else {
              Toast({
                duration: 1500,
                forbidClick: true,
                message: '上传失败，文件类型错误！'
              })
            }
          })
          .catch(err => {
            Toast(err.response.data.message)
            console.log('err', err)
          })
      })
    },
    async ocr() {
      if (this.short_name == 'tianqing' && (this.item.label.indexOf('诊断') != -1 || this.item.label.indexOf('处方单') != -1)) {
        let name = this.item.label.indexOf('诊断') != -1 ? '诊断' : '处方单'
        console.log('进入ocr@')
        if (this.images.length > 0) {
          await getWordOcr({
            images: this.images
          })
            .then(res => {
              this.$emit('getimg', res, name)
            })
            .catch(err => {
              Toast(err.response.data.message)
            })
        } else {
          this.$emit('getimg', '', name)
        }
        // this.images = ["http://ecasev2-api.papv2.sungotech.com/upload/image/202108/10/0c77ddbfccbdb2cd19f0d829dbe3c7af.jpg"]
      } else {
        console.log('添加图片不ocr')
        let list = this.preImages.concat(this.images)
        this.item.value = list
      }
    },
    async deleteOcr() {
      if ((this.short_name == 'tianqing' && this.item.label.indexOf('诊断') != -1) || this.item.label.indexOf('处方单') != -1) {
        let name = this.item.label.indexOf('诊断') != -1 ? '删除诊断' : '删除处方单'
        console.log('进入删除ocr@')
        if (this.preImages.length > 0) {
          await getWordOcr({
            images: this.preImages
          })
            .then(res => {
              this.$emit('getimg', res, name)
            })
            .catch(err => {
              Toast(err.response.data.message)
            })
        } else {
          this.$emit('getimg', '', name)
        }
      } else {
        console.log('删除图片不ocr')
        let list = this.preImages.concat(this.images)
        this.item.value = list
      }
    }
  }
}
</script>

<style lang="scss">
.uploadPic {
  position: relative;
  // .van-dialog__header {
  //     color: #2C3E50;
  // }

  .van-uploader__preview-delete {
    overflow: hidden;
  }

  .picBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .list {
      height: 50px;
      width: 50px;
      margin-right: 10px;
      margin-bottom: 5px;
      position: relative;

      .img {
        height: 100%;
        width: 100%;
      }

      .clear {
        position: absolute;
        right: -2px;
        top: -2px;
        height: 13px;
        width: 13px;
      }
    }

    .add {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      color: #272727;
      border: 1px dashed #272727;

      img {
        height: 22px;
        width: 22px;
        margin-bottom: 2px;
      }

      span {
        line-height: 12px;
      }
    }
  }

  .remarks {
    font-size: 12px;
    line-height: 1.4;
    color: #969799;
    margin-top: 5px;
  }
  .van-field__control .remarks {
    margin-top: 5px;
  }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
}

.uploadPic::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 1px solid #ebedf0;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
</style>
